// Color variables

  $text-primary : #091E42;
  $text-secondary : #0072E3;
  $text-black : #000000;
  $text-white : #ffffff;
  $text-grey : #6B788E;
  $text-grey-light : #98A1B0;
  $button-primary : #0072E3;
  $button-primary-disabled : #AABBFF;
  $button-primary-text : #ffffff;
  $button-seconday : #3e445e;
  $bg-primary : #ffffff;
  $bg-secondary : #0072E3;
  $bg-light-blue: #F7FBFE;



  // Font Sizes
  $font-small: calc(1rem + 0.2vw);
  $font-regular: calc(0.8rem + 0.2vw);
  $font-title: calc(0.8rem + 0.4vw);
  $font-medium: calc(1.2rem + 1vw);
  $font-large: calc(1.5rem + 2vw);
@function color($color-styles) {
  @return map-get($colors,$color-styles);
};


@function font-size($font-size-style) {
  @return map-get($font-sizes, $font-size-style);
}



:root {
  /* dark shades of primary color*/
  --clr-text-primary: #45474a;
  --clr-text-secondary: #979FA1;
  --clr-text-tertiary: #44474A;
  --clr-text-quaternary: #787B7E;
  --clr-button-primary: #3394ca; 
  --clr-button-secondary: #3e445e;
  --clr-button-tertiary: #ACCDFF;
  --clr-button-quaternary: #ACFFB9;
  --clr-button-text-primary: #ffffff;
  --clr-component-primary: #ffffff;
  --clr-background-primary:  #eeefef;
  --clr-grey-1: #f5f6f7;

  --transition: all 0.2s linear;
  --spacing: 0.1rem;
  --radius: 14px;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
p {
  font-size: $font-regular;
  color: $text-grey;
}
h5 {
  font-size: $font-title;
  color: $text-primary;
}
