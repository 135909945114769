.events {
    padding: 0px 96px;

    @include laptopView {
        padding: 0px 66px;
    }
}

.events-cards {
    margin: 50px 0;

    &_items {
        position: relative;

        border-radius: 12px;

        & p:first-of-type {
            height: calc(120px + 10vh);
            width: 100%;
            @include flexBox(center, center);
            border-radius: 12px 12px 0 0;
            position: relative;

            & img {
                border-radius: 12px 12px 0 0;
                height: 100%;
                width: 100%;
            }

            .broke-date {
                position: absolute;
                left: 20px;
                bottom: 20px;
                background-color: $bg-secondary;

                // @include flexBox(center,flex-start,column);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                // top: 20%;
                padding: 10px calc(10px + 0.5vw);
                border-radius: 8px;

                & span {
                    color: $text-white;
                    line-height: 30px;
                }

                & span:first-of-type {
                    font-size: calc(1rem + 0.8vw);
                    margin-left: -8px;
                }
            }
        }

        & p:nth-of-type(2) {
            padding: calc(15px + 0.4vw);

        }

        & p:nth-of-type(3) {
            padding: 0 calc(15px + 0.4vw);
            font-size: $font-title;
            color: $text-primary;
        }

        & p:nth-of-type(4) {
            margin: calc(10px + 0.4vh) 0;
            padding: 0 calc(15px + 0.4vw);
        }

        & p:nth-of-type(5) {
            margin: calc(10px + 0.4vh) 0;
            padding: 0 calc(15px + 0.4vw);
        }

        & p:nth-of-type(6) {
            padding: 0 calc(15px + 0.4vw);
            @include flexBox(flex-start, center);
            gap: 7px;
            margin-bottom: 40px;
            cursor: pointer;

            .learn-more-arrow {

                transition: transform 0.3s ease-in-out;
            }

            &:hover .learn-more-arrow {

                transform: translateX(10px);
                transition: transform 0.3s ease-in-out;
            }

            &:hover {
                color: $text-secondary;
            }
        }

        transition: all 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
        border: 1px solid #ebedf000;

        &::after {
            position: absolute;
            content: "";
            height: 9px;
            width: 100%;
            top: 98%;
            border-radius: 0 0 12px 12px;
            background-color: #0172e3;
            opacity: 0;
        }

        &:hover::after {
            opacity: 1;
            transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;

        }

        &:hover {
            box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
            transform: scale(1);
        }
    }
}

.banner-container {
    margin: 100px 0 40px 0;
    padding: 0px 96px;

    @include laptopView {
        padding: 0px 66px;
    }

    height: 370px;
    width: 100%;
    position: relative;

    &>img {
        height: 100%;
        width: 100%;
    }

    &::after {
        position: absolute;
        content: "";
        z-index: 1;
        top: 0;
        left: 96px;
        bottom: 0;
        right: 96px;
        border-radius: 12px;
        background-color: #b0d3f644;

        @include laptopView {
            left: 66px;
            right: 66px;
        }
    }
}

.banner-hero-img {
    @extend .hero-img;
    width: 100%;
    margin-right: calc(10px + 3vw);

    & img {
        height: calc(380px + 5vh);
        width: calc(380px + 5vh);
        z-index: 2;
    }

    &-container {
        @extend .hero-img-container;

        &>p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -56%);
            height: calc(280px + 5vh);
            width: calc(280px + 5vh);
            background-color: #d8eafc;
            z-index: 1;
            border-radius: 50%;
            @include flexBox(center, center);
            animation: changeBgColord 2s linear infinite;
            transition-duration: 1s;

            &>span {
                position: absolute;
                background-color: #ffca7a;
                height: calc(260px + 5vh);
                width: calc(260px + 5vh);
                border-radius: 50%;
            }
        }

        .hero-universites {
            position: absolute;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            top: 86%;
            z-index: 4;
            left: 0%;
            transform: translate(-50%, -10%);
            background-color: #ffffff;
            padding: 10px;
            box-shadow: 0px 8px 16px 0px #0072E314;
            border-radius: 12px;
            gap: 5px;

            &>p {
                background-color: #e6f1fc;
                padding: 4px;
                white-space: no-wrap;
                border-radius: 50%;
            }

            & div {
                & p:first-of-type {
                    font-size: 24px;
                }
            }

        }

        .hero-us-img {
            position: absolute;
            top: 0%;
            left: -8%;
            z-index: 4;
            background-color: #ffffff;
            border-radius: 12px;
            box-shadow: 0px 25px 40px 0px #0000001F;
            height: 60px;
            width: 60px;
            animation: spin 2s linear forwards;
            animation-delay: 1s;

            & img {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

        }

        .hero-uk-img {
            position: absolute;
            top: 50%;
            left: -16%;
            z-index: 4;
            background-color: #ffffff;
            border-radius: 12px;
            box-shadow: 0px 25px 40px 0px #0000001F;
            height: 60px;
            width: 60px;
            animation: spin 2s linear forwards;
            animation-delay: 1s;

            & img {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

        }
    }
}

.banner-container_details {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    width: 60%;
    padding-right: 100px;
    padding-left: calc(30px + 1vw);

    & h1:first-of-type {
        font-size: 40px;
        font-weight: bold;
    }

    & h1:nth-of-type(2) {
        font-size: 40px;
        color: $bg-secondary;
        font-weight: bold;
    }

    & p {
        margin-bottom: 30px;
    }

    & button {
        @extend .primary-button;
        padding: 15px 50px;
    }
}

.banner-container_img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 40%;
    right: 0;
    background-color: rgb(107, 79, 136)
}

.event-detail {
    font-family: Inter;
    max-width: 85rem;
    margin: 0 auto;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 29px;
    }
}

.bubble-text {
    display: flex;
    justify-content: start;
    align-items: left;
    flex-direction: row;
    gap: 12px;
    max-width: fit-content;
    flex-wrap: wrap;

    p {
        color: #091E42;
        border: 1px solid #edeef1;
        border-radius: 25px;
        padding: 8px 12px;
        transition: all 0.2s ease-in-out;
        font-size: 14px;

        &:hover {
            background-color: #0072E3;
            cursor: pointer;
            color: #ffffff;
            transition: all 0.2s ease-in-out;
        }
    }
}
