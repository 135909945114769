.pagination-head {
    border: 1px solid #eeeff2;
    @include flexBox(space-between, center);
    padding: calc(10px + 1vw);
    border-radius: 8px;

}
.pagination-display {
    & p {
        color: $text-grey;
    }
    @include tabView {
        background-color: yellow;
        transform: scale(0);
    }
}
.pagination_body {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    margin-bottom: 5vh;
  }
  .pagination-container {
    gap: 20px;
    display: flex;
    align-items: center;
  }
  .cutom_pagination {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    list-style: none;
    cursor: pointer;
    gap: 10px;
  }
  
  .cutom_pagination a {
    padding: 10px 15px;
    border-radius: 8px;
    color: $text-grey;
    border: 1px solid #eeeff2;
  }
  
  .customPagination__link {
    font-weight: normal;
    color: $text-grey;
  }
  
  .customPagination__link--active a {
    color: #fff !important;
    background: $bg-secondary;
    border: 0px solid #eeeff2;
  }
  
  .customPagination__link--disabled a {
    color: $text-grey;
  }
  .customPagination-perPage label {
    margin-right: 10px;
    color: $text-grey;
  }
  .customPagination-perPage select {
    height: 40px;
    width: 80px;
    border-radius: 6px;
    background-color: #f3f4f6;

    color: $text-grey;
  }
  