*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
  transform: skewX(1.5);
}

::-webkit-scrollbar-track {
  background-color: rgb(216, 215, 213);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(186, 190, 174);
  border-radius: 50px;
}

:root {
  /* dark shades of primary color*/
  --clr-text-primary: #45474a;
  --clr-text-secondary: #979FA1;
  --clr-text-tertiary: #44474A;
  --clr-text-quaternary: #787B7E;
  --clr-button-primary: #3394ca;
  --clr-button-secondary: #3e445e;
  --clr-button-tertiary: #ACCDFF;
  --clr-button-quaternary: #ACFFB9;
  --clr-button-text-primary: #ffffff;
  --clr-component-primary: #ffffff;
  --clr-background-primary: #eeefef;
  --clr-grey-1: #f5f6f7;
  --transition: all 0.2s linear;
  --spacing: 0.1rem;
  --radius: 14px;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

p {
  font-size: calc(0.8rem + 0.2vw);
  color: #6B788E;
}

h5 {
  font-size: calc(0.8rem + 0.4vw);
  color: #091E42;
}

.primary-button, .banner-container_details button {
  background-color: #0072E3;
  color: #ffffff;
  border-radius: 8px;
  padding: 5px 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
}
.primary-button [disabled=disabled], .banner-container_details button [disabled=disabled] {
  background-color: #AABBFF;
}

.secondary-button {
  color: #091E42;
  border-radius: 8px;
  padding: 5px 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border: 1px solid #EBEDF0;
}

.pagination-head {
  border: 1px solid #eeeff2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: calc(10px + 1vw);
  border-radius: 8px;
}

.pagination-display p {
  color: #6B788E;
}
@media screen and (max-width: 960px) {
  .pagination-display {
    background-color: yellow;
    transform: scale(0);
  }
}

.pagination_body {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-bottom: 5vh;
}

.pagination-container {
  gap: 20px;
  display: flex;
  align-items: center;
}

.cutom_pagination {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  cursor: pointer;
  gap: 10px;
}

.cutom_pagination a {
  padding: 10px 15px;
  border-radius: 8px;
  color: #6B788E;
  border: 1px solid #eeeff2;
}

.customPagination__link {
  font-weight: normal;
  color: #6B788E;
}

.customPagination__link--active a {
  color: #fff !important;
  background: #0072E3;
  border: 0px solid #eeeff2;
}

.customPagination__link--disabled a {
  color: #6B788E;
}

.customPagination-perPage label {
  margin-right: 10px;
  color: #6B788E;
}

.customPagination-perPage select {
  height: 40px;
  width: 80px;
  border-radius: 6px;
  background-color: #f3f4f6;
  color: #6B788E;
}

.custom-select {
  padding: 5px;
  padding: 10px;
  border: 1px solid #dfe2e6;
  border-radius: 8px;
}

.headingtop {
  height: 80px;
  padding: 0px 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #EBEDF0;
}
@media screen and (max-width: 1600px) {
  .headingtop {
    padding: 0px 66px;
  }
}
.headingtop-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.headingtop-logo img {
  height: 40px;
  min-width: auto;
  margin-right: 30px;
}
.headingtop-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.headingtop-courses, .browse-by-country.active, .browse-by-country {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  height: calc(100vh - 80px);
  width: 100vw;
  transform: scaleY(0);
  opacity: 0;
  margin-top: 80px;
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  transform-origin: top;
}
.headingtop-courses-active, .browse-by-country.active {
  position: fixed;
  top: 0px;
  left: 0;
  margin-top: 80px;
  z-index: 21;
  height: calc(100vh - 80px);
  width: 100vw;
  transform: scaleY(1);
  background-color: rgba(40, 45, 53, 0.5254901961);
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  transform-origin: top;
}

.headingtop-navigation-menu {
  display: flex;
  gap: calc(10px + 1vw);
}

.menu-item {
  height: 80px;
  padding: 0px 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #091E42;
}

.menu-item.active {
  border-bottom: 2px solid #0072E3;
}

.menu-item:hover {
  background-color: #EBEDF0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.our-sources, .browse-by-country-container {
  height: 70%;
  background-color: #f0f9ff;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.our-sources-courses {
  width: 70%;
  height: 100%;
  background-color: #f2f8fe;
  padding: 0px 96px;
}
@media screen and (max-width: 1600px) {
  .our-sources-courses {
    padding: 0px 66px;
  }
}
.our-sources-persuits {
  width: 30%;
  height: 100%;
  position: relative;
}
.our-sources-persuits img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.headtop-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 2vw;
}

.browse-by-country.active {
  overflow-y: auto;
}

.browse-by-country-container {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.browse_by_county {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  padding: 0px 96px;
}
@media screen and (max-width: 1600px) {
  .browse_by_county {
    padding: 0px 66px;
  }
}
.browse_by_county-tabs {
  margin: calc(10px + 2vw) 0;
  background-color: #f2f8fe;
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 80vh;
}
.browse_by_county-tabs > div {
  padding: calc(8px + 0.5vh);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: calc(12px + 0.4vw);
}
.browse_by_county-tabs > div > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 6px;
}
.browse_by_county-tabs > div div p {
  color: #091E42;
}
.browse_by_county-tabs > div.active {
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
  background-color: white;
  border-radius: 12px;
}
.browse_by_county-tabs > div.active p {
  color: #0072E3;
}
.browse_by_county-tabs > div:hover {
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
  padding: calc(8px + 0.5vh);
  background-color: white;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
}
.browse_by_county-content {
  margin: calc(10px + 2vw) 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
  background-color: #ffffff;
  width: 80%;
  border-radius: 12px;
}

.breadboard {
  display: flex;
  align-items: center;
  gap: calc(5px + 0.4vw);
  margin: 20px 0;
}

.close-showBrowse-by-country {
  background-color: rgb(181, 129, 129);
  width: 30px;
  border-radius: 6px;
  display: none;
  transition: all 0.2s ease-in-out;
  transform: scaleX(0);
}

.close-showBrowse-by-country.active {
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transform: scaleX(1);
}

.browse_by_county-content_item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.browse_by_county-content_item-details {
  width: 75%;
  padding: calc(15px + 0.5vh);
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.browse_by_county-content_item-details > div {
  display: flex;
  flex-direction: column;
  gap: calc(15px + 1vh);
}
.browse_by_county-content_item-details > div h1 {
  margin-bottom: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.browse_by_county-content_item-details > div h1:hover {
  color: #0072E3;
}
.browse_by_county-content_item-details > div p {
  line-height: 30px;
}
.browse_by_county-content_item-img {
  width: 25%;
}

.outward-line {
  position: relative;
}

.outward-line::after {
  position: absolute;
  content: "";
  top: 106%;
  left: 0;
  z-index: 10;
  height: 1.25px;
  width: 20px;
  background-color: #2b2b2b;
}

.container {
  position: relative;
}
.container > img {
  position: absolute;
  z-index: 1;
  bottom: 80px;
}
.container .hero {
  position: absolute;
  z-index: 10;
}
.container::after {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(176, 211, 246, 0.2666666667);
}

.hero-container {
  width: 60%;
  margin-top: 60px;
}
.hero-container > p:first-of-type {
  font-size: 18px;
  color: #0072E3;
}
.hero-container > p:nth-of-type(2) {
  margin-top: 5px;
  line-height: 50px;
  font-size: calc(40px + 1.1vw);
  color: #000000;
}
.hero-container > p:nth-of-type(2) span {
  color: red;
}
.hero-container > p:nth-of-type(3) {
  font-size: calc(40px + 1.1vw);
  margin-top: 0px;
  color: #000000;
}
.hero-container > p:nth-of-type(4) {
  margin-top: 15px;
  line-height: 30px;
  font-size: calc(0.8rem + 0.4vw);
  color: #091E42;
}
.hero-container > p:nth-of-type(5) {
  margin-top: 15px;
  line-height: 30px;
  font-size: calc(0.8rem + 0.4vw);
  color: #091E42;
}

.hero-img, .banner-hero-img {
  width: 40%;
  top: 10px;
  position: relative;
}
.hero-img img, .banner-hero-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 3;
  width: 100%;
  height: calc(370px + 15vh);
  -o-object-fit: contain;
     object-fit: contain;
}
.hero-img-container > p, .banner-hero-img-container > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -56%);
  height: calc(300px + 15vh);
  width: calc(300px + 15vh);
  background-color: #b0d3f6;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  animation: changeBgColor 2s linear infinite;
  transition-duration: 1s;
}
.hero-img-container > p > span, .banner-hero-img-container > p > span {
  position: absolute;
  background-color: red;
  height: calc(280px + 15vh);
  width: calc(280px + 15vh);
  border-radius: 50%;
}

.hero-universites {
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  top: 95%;
  z-index: 4;
  left: 50%;
  transform: translate(-50%, -10%);
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
  border-radius: 12px;
  gap: 5px;
}
.hero-universites > p {
  background-color: #e6f1fc;
  padding: 4px;
  white-space: no-wrap;
  border-radius: 50%;
}
.hero-universites div p:first-of-type {
  font-size: 24px;
}

.hero-canada-img {
  position: absolute;
  top: 68%;
  left: 93%;
  z-index: 4;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  height: 60px;
  width: 60px;
  animation: spin 2s linear forwards;
  animation-delay: 5s;
}
.hero-canada-img img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hero-australia-img {
  position: absolute;
  top: 15%;
  left: 88%;
  z-index: 4;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  height: 60px;
  width: 60px;
  animation: spin 2s linear forwards;
  animation-delay: 7s;
}
.hero-australia-img img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hero-us-img {
  position: absolute;
  top: -20%;
  left: 1%;
  z-index: 4;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  height: 60px;
  width: 60px;
  animation: spin 2s linear forwards;
  animation-delay: 1s;
}
.hero-us-img img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hero-uk-img {
  position: absolute;
  top: 40%;
  left: -14%;
  z-index: 4;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  height: 60px;
  width: 60px;
  position: relative;
  animation: spin 2s linear forwards;
  animation-delay: 3s;
}
.hero-uk-img img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes changeBgColor {
  0% {
    background-color: #b0d3f6;
  }
  50% {
    background-color: #87a7cc;
  }
  100% {
    background-color: #b0d3f6;
  }
}
/* Delay before starting each subsequent animation */
.hero-uk-img:nth-child(odd) {
  animation-delay: 5s; /* Delay after every even iteration */
}

.hero-search {
  margin-top: 4vh;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.0392156863);
  border-radius: 12px;
  gap: 10px;
}
.hero-search-courses {
  width: 46%;
  background-color: #f5f6f7;
  padding: 15px;
  border-radius: 8px;
}
.hero-search-location {
  width: 40%;
  background-color: #f5f6f7;
  padding: 15px;
  border-radius: 8px;
}
.hero-search-btn, .filtersearch-input_submit {
  width: 16%;
}
.hero-search-btn button, .filtersearch-input_submit button {
  background-color: #0072E3;
  color: #ffffff;
  width: 100%;
  height: 52px;
  padding: 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 8px;
}

.hero-trusted-clients {
  position: relative;
  margin-top: 4vh;
  display: flex;
  align-items: center;
}
.hero-trusted-clients > p {
  position: absolute;
  top: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  border: 3px solid #ffffff;
}
.hero-trusted-clients > p img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.hero-trusted-clients > p:nth-of-type(1) {
  background-color: rgb(138, 138, 105);
  z-index: 10;
}
.hero-trusted-clients p:nth-of-type(2) {
  left: 26px;
  background-color: rgb(138, 138, 105);
  z-index: 11;
}
.hero-trusted-clients p:nth-of-type(3) {
  left: 49px;
  background-color: rgb(138, 138, 105);
  z-index: 12;
}
.hero-trusted-clients p:nth-of-type(4) {
  left: 69px;
  background-color: rgb(138, 138, 105);
  z-index: 13;
}
.hero-trusted-clients p:nth-of-type(5) {
  left: 89px;
  background-color: #0072E3;
  z-index: 13;
}
.hero-trusted-clients div {
  margin-left: 150px;
  margin-top: 5px;
}
.hero-trusted-clients div p {
  color: #415370;
}

.hero-bottom {
  padding: 0px 96px;
  position: absolute;
  z-index: 20;
  top: 730px;
  gap: 4vw;
}
@media screen and (max-width: 4220px) {
  .hero-bottom {
    top: 1000px;
  }
}
@media screen and (max-width: 1600px) {
  .hero-bottom {
    padding: 0px 66px;
    top: 730px;
  }
}
.hero-bottom > div {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
}

.fingertips {
  position: relative;
}
.fingertips span {
  position: absolute;
  z-index: 10;
  top: 98%;
  left: -10px;
  width: 115%;
}
.fingertips span img {
  width: 100%;
}

.client-number {
  background-color: #ffffff;
  color: #ffffff;
}

.events {
  padding: 0px 96px;
}
@media screen and (max-width: 1600px) {
  .events {
    padding: 0px 66px;
  }
}

.events-cards {
  margin: 50px 0;
}
.events-cards_items {
  position: relative;
  border-radius: 12px;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border: 1px solid rgba(235, 237, 240, 0);
}
.events-cards_items p:first-of-type {
  height: calc(120px + 10vh);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 12px 12px 0 0;
  position: relative;
}
.events-cards_items p:first-of-type img {
  border-radius: 12px 12px 0 0;
  height: 100%;
  width: 100%;
}
.events-cards_items p:first-of-type .broke-date {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: #0072E3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px calc(10px + 0.5vw);
  border-radius: 8px;
}
.events-cards_items p:first-of-type .broke-date span {
  color: #ffffff;
  line-height: 30px;
}
.events-cards_items p:first-of-type .broke-date span:first-of-type {
  font-size: calc(1rem + 0.8vw);
  margin-left: -8px;
}
.events-cards_items p:nth-of-type(2) {
  padding: calc(15px + 0.4vw);
}
.events-cards_items p:nth-of-type(3) {
  padding: 0 calc(15px + 0.4vw);
  font-size: calc(0.8rem + 0.4vw);
  color: #091E42;
}
.events-cards_items p:nth-of-type(4) {
  margin: calc(10px + 0.4vh) 0;
  padding: 0 calc(15px + 0.4vw);
}
.events-cards_items p:nth-of-type(5) {
  margin: calc(10px + 0.4vh) 0;
  padding: 0 calc(15px + 0.4vw);
}
.events-cards_items p:nth-of-type(6) {
  padding: 0 calc(15px + 0.4vw);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 7px;
  margin-bottom: 40px;
  cursor: pointer;
}
.events-cards_items p:nth-of-type(6) .learn-more-arrow {
  transition: transform 0.3s ease-in-out;
}
.events-cards_items p:nth-of-type(6):hover .learn-more-arrow {
  transform: translateX(10px);
  transition: transform 0.3s ease-in-out;
}
.events-cards_items p:nth-of-type(6):hover {
  color: #0072E3;
}
.events-cards_items::after {
  position: absolute;
  content: "";
  height: 9px;
  width: 100%;
  top: 98%;
  border-radius: 0 0 12px 12px;
  background-color: #0172e3;
  opacity: 0;
}
.events-cards_items:hover::after {
  opacity: 1;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.events-cards_items:hover {
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  transform: scale(1);
}

.banner-container {
  margin: 100px 0 40px 0;
  padding: 0px 96px;
  height: 370px;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 1600px) {
  .banner-container {
    padding: 0px 66px;
  }
}
.banner-container > img {
  height: 100%;
  width: 100%;
}
.banner-container::after {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  left: 96px;
  bottom: 0;
  right: 96px;
  border-radius: 12px;
  background-color: rgba(176, 211, 246, 0.2666666667);
}
@media screen and (max-width: 1600px) {
  .banner-container::after {
    left: 66px;
    right: 66px;
  }
}

.banner-hero-img {
  width: 100%;
  margin-right: calc(10px + 3vw);
}
.banner-hero-img img {
  height: calc(380px + 5vh);
  width: calc(380px + 5vh);
  z-index: 2;
}
.banner-hero-img-container > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -56%);
  height: calc(280px + 5vh);
  width: calc(280px + 5vh);
  background-color: #d8eafc;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  animation: changeBgColord 2s linear infinite;
  transition-duration: 1s;
}
.banner-hero-img-container > p > span {
  position: absolute;
  background-color: #ffca7a;
  height: calc(260px + 5vh);
  width: calc(260px + 5vh);
  border-radius: 50%;
}
.banner-hero-img-container .hero-universites {
  position: absolute;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  top: 86%;
  z-index: 4;
  left: 0%;
  transform: translate(-50%, -10%);
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
  border-radius: 12px;
  gap: 5px;
}
.banner-hero-img-container .hero-universites > p {
  background-color: #e6f1fc;
  padding: 4px;
  white-space: no-wrap;
  border-radius: 50%;
}
.banner-hero-img-container .hero-universites div p:first-of-type {
  font-size: 24px;
}
.banner-hero-img-container .hero-us-img {
  position: absolute;
  top: 0%;
  left: -8%;
  z-index: 4;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  height: 60px;
  width: 60px;
  animation: spin 2s linear forwards;
  animation-delay: 1s;
}
.banner-hero-img-container .hero-us-img img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.banner-hero-img-container .hero-uk-img {
  position: absolute;
  top: 50%;
  left: -16%;
  z-index: 4;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  height: 60px;
  width: 60px;
  animation: spin 2s linear forwards;
  animation-delay: 1s;
}
.banner-hero-img-container .hero-uk-img img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.banner-container_details {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: 60%;
  padding-right: 100px;
  padding-left: calc(30px + 1vw);
}
.banner-container_details h1:first-of-type {
  font-size: 40px;
  font-weight: bold;
}
.banner-container_details h1:nth-of-type(2) {
  font-size: 40px;
  color: #0072E3;
  font-weight: bold;
}
.banner-container_details p {
  margin-bottom: 30px;
}
.banner-container_details button {
  padding: 15px 50px;
}

.banner-container_img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 40%;
  right: 0;
  background-color: rgb(107, 79, 136);
}

.event-detail {
  font-family: Inter;
  max-width: 85rem;
  margin: 0 auto;
}
.event-detail p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
}

.bubble-text {
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: row;
  gap: 12px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex-wrap: wrap;
}
.bubble-text p {
  color: #091E42;
  border: 1px solid #edeef1;
  border-radius: 25px;
  padding: 8px 12px;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
}
.bubble-text p:hover {
  background-color: #0072E3;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}

.wrapper {
  padding: 0px 96px;
  margin-top: calc(100px + 4vh);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 1600px) {
  .wrapper {
    padding: 0px 66px;
  }
}
@media screen and (max-width: 960px) {
  .wrapper {
    padding: 0px 1rem;
  }
}

.connecting-universities p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  margin-top: 24px;
  text-align: center;
  color: #6B788E;
}
.connecting-universities ul.tabs {
  background-color: var(--clr-grey-1);
}
.connecting-universities ul.tabs li {
  width: 274.67px;
  height: 73px;
  padding: 24px;
  border-radius: 100px;
  gap: 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.connecting-universities ul.tabs li.active {
  color: #0072E3;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.0784313725);
  transition-duration: 0.3s;
}

.connecting-container {
  padding: 32px 0px;
}
@media screen and (max-width: 960px) {
  .connecting-container {
    padding: 1rem 0rem;
  }
}

.heading1 {
  font-size: 2.4rem;
  font-family: Inter;
  line-height: 3.2rem;
  letter-spacing: 0em;
  text-align: left;
}
@media screen and (max-width: 960px) {
  .heading1 {
    font-size: 2rem;
    line-height: 2.2rem;
  }
}

ul.tabs {
  width: 824px;
  height: 73px;
  border-radius: 100px;
  background-color: var(--clr-grey-1);
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
}
@media screen and (max-width: 960px) {
  ul.tabs {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
}

.connecting-card {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.connecting-card .img-card img {
  border-radius: 12px;
  width: 100%;
}
.connecting-card .text-card p {
  text-align: left;
}
@media screen and (max-width: 960px) {
  .connecting-card {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-top: 1.5rem;
  }
  .connecting-card .img-card,
  .connecting-card .text-card {
    padding: 0px !important;
  }
  .connecting-card .text-card img {
    border-radius: 12px;
    width: 3rem;
  }
}

.students-world-wide {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
@media screen and (max-width: 960px) {
  .students-world-wide {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.text-secondary {
  color: #0072E3 !important;
}

.text-primary {
  color: #091E42 !important;
}

@media screen and (max-width: 960px) {
  .primary-button.tw-p-4, .banner-container_details button.tw-p-4 {
    padding: 0.6rem !important;
  }
}
.get-scholarships {
  background-color: #0072E3;
  background-size: contain;
  color: white;
}

.heading3 {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
}

.our-events {
  background-color: #F7FBFE;
}
.our-events ul.tabs {
  border-radius: 100px;
  overflow: hidden;
}
.our-events ul.tabs li {
  width: 274.67px;
  height: 73px;
  padding: 24px;
  gap: 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  color: #6B788E;
}
.our-events ul.tabs li.active {
  border-radius: 100px;
  background-color: #0072E3;
  color: white;
  transition-duration: 0.3s;
}
.our-events p {
  color: #6B788E;
}

.our-partner-uni {
  background: url("/src/Assets/Images/mesh.png") center center no-repeat;
  background-size: cover;
}
.our-partner-uni p {
  color: #6B788E;
}
.our-partner-uni .countries img {
  width: 32px;
  height: 32px;
}
.our-partner-uni .countries li {
  padding: 12px;
  border-radius: 12px;
  border: 1.5px;
  gap: 8px;
}
.our-partner-uni .countries li.active {
  border: 1.5px solid #0072E3;
  box-shadow: 0px 10px 15px 2px rgba(0, 114, 227, 0.1215686275);
}
.our-partner-uni .countries li:hover {
  box-shadow: 0px 10px 15px 2px rgba(0, 114, 227, 0.1215686275);
}
.our-partner-uni .universities img {
  width: 218px;
  height: 130px;
  padding: 12px;
}
.our-partner-uni .universities img:hover {
  box-shadow: 0px 10px 15px 2px rgba(0, 114, 227, 0.1215686275);
}

.visa-calculator {
  background: url(/src/Assets/Images/Vector.png) center center no-repeat, url(/src/Assets/Images/Frame\ 2250.png) 80% 85% no-repeat;
}
.visa-calculator .visa-info-card {
  width: 506px;
  height: 343px;
  padding: 40px;
  border-radius: 16px;
  transition: all 0.3s;
  gap: 40px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
}
.visa-calculator .visa-info-card img {
  width: 96px;
  height: 96px;
}
.visa-calculator .visa-info-topic li {
  font-family: Inter;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #98A1B0;
  width: 506px;
  height: 80px;
  padding: 24px;
  border-radius: 12px;
  gap: 12px;
}
.visa-calculator .visa-info-topic li.active {
  color: black;
  transition: all 0.3s;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
  background: url(/src/Assets/Images/icons/arrow-right.png) center right no-repeat;
}
.visa-calculator p {
  color: #6B788E;
}

.get-scholarships p {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: white;
}

.event-card {
  width: 400px;
  height: 460px;
  border-radius: 12px;
  position: relative;
  border: 1px solid #EBEDF0;
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #6B788E;
  background-color: white;
}
.event-card .date {
  left: 1rem;
  top: 6.2rem;
  width: 70px;
  height: 87px;
  padding: 8px, 16px, 16px, 16px;
  border-radius: 8px;
  background-color: #0072E3;
  color: white;
  position: absolute;
}
.event-card .date .day {
  font-size: 33px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}
.event-card .date .month {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.event-card img {
  border-radius: 12px 12px 0px 0px;
}
.event-card p {
  color: #6B788E;
}

.clients-say {
  width: 1084px;
  height: 448px;
  border-radius: 16px;
  background-color: #F7FBFE;
  overflow: hidden;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
.clients-say img {
  height: auto;
}

.scholar-card {
  width: 294px;
  height: 320px;
  border-radius: 12px;
  position: relative;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.0588235294);
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #6B788E;
  background-color: white;
}
.scholar-card .rating {
  left: 1rem;
  top: 1rem;
  width: 69px;
  height: 32px;
  padding: 4px, 8px, 4px, 8px;
  border-radius: 4px;
  background-color: white;
  position: absolute;
}
.scholar-card img {
  border-radius: 12px 12px 0px 0px;
}
.scholar-card p {
  color: #6B788E;
}

.scholarship {
  padding: 0px 96px;
}
@media screen and (max-width: 1600px) {
  .scholarship {
    padding: 0px 66px;
  }
}
.scholarship-allprograms {
  margin: calc(50px + 5vh) 0;
}

.filtersearch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0072E3;
  padding: calc(25px + 0.4vw);
  border-radius: 12px;
  padding-bottom: 60px;
  position: relative;
}
.filtersearch p {
  color: #ffffff;
}
.filtersearch p:first-of-type {
  font-size: calc(1.2rem + 1vw);
  color: #ffffff;
}
.filtersearch p:nth-of-type(2) {
  margin-top: 1vh;
  padding: 0 calc(150px + 10vw);
  text-align: center;
  color: #ffffff;
}
.filtersearch-input {
  position: absolute;
  top: calc(100% - 30px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
  padding: 8px;
  gap: 6px;
  box-shadow: 0px 8px 16px 0px rgba(0, 114, 227, 0.0784313725);
  border-radius: 8px;
  width: calc(600px + 15vw);
}
.filtersearch-input_buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 10px;
  width: 14%;
}
.filtersearch-input_buttons span {
  font-size: 18px;
}
.filtersearch-input_field {
  width: 74%;
}
.filtersearch-input_field input {
  width: 100%;
  padding: 15px;
}
.filtersearch-input_submit {
  width: 12%;
}

.program-card-container {
  margin-top: 30px;
}
.program-card-container-item {
  border: 1px solid #EBEDF0;
  border-radius: 12px;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
  position: relative;
}
.program-card-container-item > p {
  font-size: calc(0.8rem + 0.2vw);
  color: #091E42;
  padding: 5px calc(12px + 1vw);
}
.program-card-container-item-img {
  height: calc(120px + 10vh);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  background-color: #0a4081;
  width: 100%;
  border-radius: 12px 12px 0 0;
}
.program-card-container-item-img p {
  position: absolute;
  top: 5%;
  left: 90%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 50%;
  width: 35px;
  background-color: white;
}
.program-card-container-item_details {
  padding: calc(12px + 1vw);
  display: flex;
  flex-direction: column;
}
.program-card-container-item_details div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.program-card-container-item::after {
  position: absolute;
  content: "";
  height: calc(5px + 0.2vw);
  width: 100%;
  top: 98%;
  border-radius: 0 0 12px 12px;
  background-color: #0172e3;
  opacity: 0;
}
.program-card-container-item:hover::after {
  opacity: 1;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.program-card-container-item:hover {
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  transform: scale(1.005);
}

.scholarship-pagination {
  margin: -40px 0 40px 0;
  padding: 0px 96px;
}
@media screen and (max-width: 1600px) {
  .scholarship-pagination {
    padding: 0px 66px;
  }
}

.QnA .author {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.QnA .author-sub {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
.QnA-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: calc(10px + 0.6vw);
}
.QnA-tabs p {
  color: #091E42;
  border: 1px solid #edeef1;
  border-radius: 25px;
  padding: calc(10px + 0.2vw) calc(10px + 0.75vw);
  transition: all 0.2s ease-in-out;
}
.QnA-tabs p:hover {
  background-color: #0072E3;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}
.QnA .country-wise {
  width: 212px;
  height: 516px;
  border-radius: 8px;
  gap: 4px;
  border: 1px solid #EBEDF0;
}
.QnA .country-wise .title {
  border-bottom: 1px solid #EBEDF0;
}
.QnA .que-in-mind {
  border: 1px solid #EBEDF0;
  width: 376px;
  height: 118px;
  border-radius: 12px;
  gap: 16px;
}
.QnA .top-contributors, .QnA .sponsored-links {
  border: 1px solid #EBEDF0;
  width: 376px;
  height: 438px;
  border-radius: 12px;
  gap: 16px;
}
.QnA .top-contributors .title, .QnA .sponsored-links .title {
  border-bottom: 1px solid #EBEDF0;
}
.QnA .top-contributors .sponsor-title, .QnA .sponsored-links .sponsor-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.QnA .top-contributors .sponsor-sub, .QnA .sponsored-links .sponsor-sub {
  font-family: Inter;
  margin-top: 0.25rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.que-card {
  border: 1px solid #EBEDF0;
  width: 612px;
  height: 313px;
  border-radius: 12px;
  text-align: left;
  font-family: Inter;
  color: #091E42;
  letter-spacing: 0em;
}
.que-card .available-answers {
  width: 132px;
  height: 25px;
  padding: 4px 8px 4px 8px;
  border-radius: 100px;
  gap: 8px;
  background-color: #E6F1FC;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
}
.que-card .que-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}
.que-card .card-footer {
  border-top: 1px solid #EBEDF0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.que-card .icons {
  width: 20px;
  height: 20px;
  color: #091E42;
}

img.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.blog-container {
  padding: 0px 96px;
}
@media screen and (max-width: 1600px) {
  .blog-container {
    padding: 0px 66px;
  }
}

.blogs {
  overflow-x: hidden;
  margin-top: 90px;
  margin-bottom: 40px;
}
.blogs-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: calc(10px + 1vw);
}
.blogs-tabs p {
  color: #091E42;
  border: 1px solid #edeef1;
  border-radius: 25px;
  padding: calc(10px + 0.2vw) calc(10px + 0.4vw);
  transition: all 0.2s ease-in-out;
}
.blogs-tabs p:hover {
  background-color: #0072E3;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}
.blogs-cards {
  margin-top: 40px;
}
.blogs-cards_items {
  position: relative;
  border-radius: 12px;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border: 1px solid rgba(235, 237, 240, 0);
}
.blogs-cards_items p:first-of-type {
  height: calc(120px + 10vh);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 12px 12px 0 0;
}
.blogs-cards_items p:first-of-type img {
  border-radius: 12px 12px 0 0;
  height: 100%;
  width: 100%;
}
.blogs-cards_items p:nth-of-type(2) {
  padding: calc(15px + 0.4vw);
}
.blogs-cards_items p:nth-of-type(3) {
  padding: 0 calc(15px + 0.4vw);
  font-size: calc(0.8rem + 0.4vw);
  color: #091E42;
}
.blogs-cards_items p:nth-of-type(4) {
  margin: calc(10px + 0.4vh) 0;
  padding: 0 calc(15px + 0.4vw);
}
.blogs-cards_items p:nth-of-type(5) {
  padding: 0 calc(15px + 0.4vw);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 7px;
  margin-bottom: 20px;
  cursor: pointer;
}
.blogs-cards_items p:nth-of-type(5) .learn-more-arrow {
  transition: transform 0.3s ease-in-out;
}
.blogs-cards_items p:nth-of-type(5):hover .learn-more-arrow {
  transform: translateX(10px);
  transition: transform 0.3s ease-in-out;
}
.blogs-cards_items p:nth-of-type(5):hover {
  color: #0072E3;
}
.blogs-cards_items::after {
  position: absolute;
  content: "";
  height: 9px;
  width: 100%;
  top: 98%;
  border-radius: 0 0 12px 12px;
  background-color: #0172e3;
  opacity: 0;
}
.blogs-cards_items:hover::after {
  opacity: 1;
  transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.blogs-cards_items:hover {
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
  transform: scale(1);
}
.blogs-pagination {
  margin: 50px 0;
}

.login-container {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: relative;
}
.login-container .login {
  border-radius: 12px;
  background-color: #FFF;
  overflow: hidden;
  max-width: 64rem;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  margin: auto;
  inset: 0; /* sets left, right, top & bottom to 0 */
}

.side-banner {
  background: url(/src/Assets/Images/side-banner.png) left top no-repeat;
  background-size: contain;
}

.dash {
  height: 1px;
  width: 100%;
  background-color: #EBEDF0;
}

.footer {
  overflow-x: hidden;
  background-color: #f7fbfe;
  padding: 0px 96px;
}
@media screen and (max-width: 1600px) {
  .footer {
    padding: 0px 66px;
  }
}
.footer-top {
  padding: calc(20px + 2.5vh) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.footer-top p img {
  height: 50px;
  min-width: auto;
  margin-right: 30px;
}
.footer-top p:last-of-type {
  margin-top: 15px;
  color: #091E42;
}
.footer-top_media {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer-top_media p {
  font-size: calc(0.8rem + 0.4vw);
  color: #091E42;
}
.footer-top_media p span {
  padding: 10px;
  border: 1px solid gray;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
.footer-contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: calc(20px + 2.5vh) 0;
}
.footer-contact div p:first-of-type {
  color: #091E42;
  font-size: calc(0.8rem + 0.4vw);
  margin-bottom: calc(10px + 1vh);
  font-weight: bold;
}
.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: calc(20px + 2.5vh) 0;
}
.footer-links div p:first-of-type {
  color: #091E42;
  font-size: calc(0.8rem + 0.4vw);
  margin-bottom: calc(10px + 1vh);
  font-weight: bold;
}

.footer-copyright {
  background-color: #001936;
  padding: 0px 96px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media screen and (max-width: 1600px) {
  .footer-copyright {
    padding: 0px 66px;
  }
}
.footer-copyright p {
  color: #ffffff;
  font-size: calc(0.8rem + 0.4vw);
}
.footer-copyright div {
  border: 1.2px solid #ffffff;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.footer-copyright div button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 3px;
  color: #ffffff;
  padding: 10px 15px;
  transition: all 0.2s ease-in-out;
}
.footer-copyright div button:hover {
  color: #0e1216;
}
.footer-copyright div:hover {
  background-color: white;
  color: #0e1216;
}/*# sourceMappingURL=index.css.map */