.get-scholarships {
    // background: url('/src/Assets/Images/BG\ blue\ mist.png');
    background-color: $text-secondary;
    background-size: contain;
    color: white;
}

.heading3 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
}

.our-events {
    background-color: $bg-light-blue;

    ul.tabs {
        border-radius: 100px;
        overflow: hidden;

        li {
            width: 274.67px;
            height: 73px;
            padding: 24px;
            gap: 12px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            background-color: white;
            color: $text-grey;

            &.active {
                border-radius: 100px;

                background-color: $text-secondary;
                color: white;
                transition-duration: 0.3s;
            }
        }
    }

    p {
        color: $text-grey
    }

}

.our-partner-uni {
    background: url('/src/Assets/Images/mesh.png') center center no-repeat;
    background-size: cover;

    p {
        color: $text-grey
    }

    .countries {
        img {
            width: 32px;
            height: 32px;

        }

        li {
            padding: 12px;
            border-radius: 12px;
            border: 1.5px;
            gap: 8px;

            &.active {
                border: 1.5px solid #0072E3;
                box-shadow: 0px 10px 15px 2px #0072E31F;
            }

            &:hover {
                box-shadow: 0px 10px 15px 2px #0072E31F;
            }
        }
    }

    .universities {
        img {
            width: 218px;
            height: 130px;
            padding: 12px;

            &:hover {
                box-shadow: 0px 10px 15px 2px #0072E31F;
            }
        }
    }

}

.visa-calculator {
    background: url(/src/Assets/Images/Vector.png) center center no-repeat, url(/src/Assets/Images/Frame\ 2250.png) 80% 85% no-repeat;

    .visa-info-card {
        width: 506px;
        height: 343px;
        padding: 40px;
        border-radius: 16px;
        transition: all 0.3s;
        gap: 40px;
        background-color: white;
        box-shadow: 0px 8px 16px 0px #0072E314;

        img {
            width: 96px;
            height: 96px
        }
    }

    .visa-info-topic li {
        font-family: Inter;
        font-size: 23px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $text-grey-light;

        width: 506px;
        height: 80px;
        padding: 24px;
        border-radius: 12px;
        gap: 12px;

        &.active {
            color: black;
            transition: all 0.3s;
            background-color: white;
            box-shadow: 0px 8px 16px 0px #0072E314;
            background: url(/src/Assets/Images/icons/arrow-right.png) center right no-repeat;
        }
    }

    p {
        color: $text-grey
    }
}


.get-scholarships p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: white;
}

.event-card {
    width: 400px;
    height: 460px;
    border-radius: 12px;
    position: relative;
    border: 1px solid #EBEDF0;
    font-family: Inter;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $text-grey;
    background-color: white;

    .date {
        left: 1rem;
        top: 6.2rem;
        width: 70px;
        height: 87px;
        padding: 8px, 16px, 16px, 16px;
        border-radius: 8px;
        background-color: $text-secondary;
        color: white;
        position: absolute;

        .day {
            font-size: 33px;
            font-weight: 700;
            line-height: 32px;
            text-align: center;
        }

        .month {
            font-size: 18px;
            font-weight: 400;
            line-height: 25px;
            text-align: center;

        }
    }

    img {
        border-radius: 12px 12px 0px 0px;
    }

    p {
        color: $text-grey;
    }
}

.clients-say {
    width: 1084px;
    height: 448px;
    border-radius: 16px;
    background-color: $bg-light-blue;
    overflow: hidden;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;


    img {
        height: auto;
    }
}

.scholar-card {
    width: 294px;
    height: 320px;
    border-radius: 12px;
    position: relative;
    box-shadow: 0px 4px 8px 0px #0000000F;
    font-family: Inter;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $text-grey;
    background-color: white;

    .rating {
        left: 1rem;
        top: 1rem;
        width: 69px;
        height: 32px;
        padding: 4px, 8px, 4px, 8px;
        border-radius: 4px;
        background-color: white;
        position: absolute;
    }

    img {
        border-radius: 12px 12px 0px 0px;
    }

    p {
        color: $text-grey;
    }
}