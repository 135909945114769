@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
    position: relative;
    width: 100%;
}
.hero {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5vw;
    background-size: cover; 
    background-position: center;
    padding: 0px 96px;

}


@media screen and (max-width: 4220px ) {
      .container {
        height: 1000px;

    }
}


@media screen and (max-width: 1600px ) {
    .hero {
        padding: 0px 66px;
    
    }
    .container {
        height: 700px;

    }

}