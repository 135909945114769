*,
::before,
::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scrollbar-width: thin;
}
::-webkit-scrollbar {
    width: 5px;
    transform: skewX(1.5);

}
::-webkit-scrollbar-track {
    background-color: rgb(216, 215, 213);
}
::-webkit-scrollbar-thumb {
    background-color: rgb(186, 190, 174);
    border-radius: 50px;
}
