.QnA {

    .author {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    .author-sub {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
    }


    &-tabs {
        @include flexBox(center, center);
        gap: calc(10px + 0.6vw);

        & p {
            color: $text-primary;
            border: 1px solid #edeef1;
            border-radius: 25px;
            padding: calc(10px + 0.2vw) calc(10px + 0.75vw);
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: $bg-secondary;
                cursor: pointer;
                color: $text-white;
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .country-wise {
        width: 212px;
        height: 516px;
        border-radius: 8px;
        gap: 4px;
        border: 1px solid #EBEDF0;

        .title {
            border-bottom: 1px solid #EBEDF0;
        }
    }

    .que-in-mind {
        border: 1px solid #EBEDF0;
        width: 376px;
        height: 118px;
        border-radius: 12px;
        gap: 16px;
    }

    .top-contributors, .sponsored-links {
        border: 1px solid #EBEDF0;
        width: 376px;
        height: 438px;
        border-radius: 12px;
        gap: 16px;

        .title {
            border-bottom: 1px solid #EBEDF0;
        }

        .sponsor-title {
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
        }

        .sponsor-sub {
            font-family: Inter;
            margin-top: 0.25rem;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
        }
    }
}

.que-card {
    border: 1px solid #EBEDF0;
    width: 612px;
    height: 313px;
    border-radius: 12px;
    text-align: left;
    font-family: Inter;
    color: $text-primary;
    letter-spacing: 0em;

    .available-answers {
        width: 132px;
        height: 25px;
        padding: 4px 8px 4px 8px;
        border-radius: 100px;
        gap: 8px;
        background-color: #E6F1FC;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        text-align: center;
    }

    .que-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
    }

    .card-footer {
        border-top: 1px solid #EBEDF0;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }

    .icons {
        width: 20px;
        height: 20px;
        color: $text-primary
    }
}

img.avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}