.primary-button {
    background-color: $button-primary;
    color: $button-primary-text;
    border-radius: 8px;
    padding: 5px 10px;
    border: none;
    @include flexBox(center,center);
    flex-wrap: nowrap;
    gap: 5px;
    
}
.secondary-button {
    color: $text-primary;
    border-radius: 8px;
    padding: 5px 10px;
    border: none;
    @include flexBox(center,center);
    gap: 5px;
    border: 1px solid #EBEDF0;
}