.footer {
    overflow-x: hidden;
    background-color: #f7fbfe;
    padding: 0px 96px;
    @include laptopView {
        padding: 0px 66px;
    }
    &-top {
        padding: calc(20px + 2.5vh) 0;
        @include flexBox(space-between,center);
        & p {
            & img {
                
                    height: 50px;
                    min-width: auto;
                    margin-right: 30px
                    
                }
    
            
        }
        & p:last-of-type {
            margin-top: 15px;
            color: $text-primary;
        }
        &_media {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            p {
                font-size: $font-title;
                color: $text-primary;
                & span {
                    padding: 10px;
                    border: 1px solid gray;
                    // background-color: rgb(67, 67, 58);
                    height: 80px;
                    width: 80px;

                    border-radius: 50%;
                }
            }
        }
    }
    &-contact {
        @include flexBox(space-between,flex-start);
        padding: calc(20px + 2.5vh) 0;
        & div {

            & p:first-of-type {
                color: $text-primary;
                font-size: $font-title;
                margin-bottom: calc(10px + 1vh);
                font-weight: bold;
            }
        }
    }
    &-links {
        @include flexBox(space-between,flex-start);
        padding: calc(20px + 2.5vh) 0 ;
        & div {

            & p:first-of-type {
                color: $text-primary;
                font-size: $font-title;
                margin-bottom: calc(10px + 1vh);
                font-weight: bold;
            }
        }
    }

}
.footer-copyright {
    background-color: #001936;
    padding: 0px 96px;
    @include laptopView {
        padding: 0px 66px;
    }
    height: 90px;
    @include flexBox(space-between,center);
    & p {
        color: $text-white;
        font-size: $font-title;
    }
    & div {
        border: 1.2px solid $text-white;
        border-radius: 12px;
        transition: all 0.2s ease-in-out;
        & button {
            @include flexBox(center,center);
            gap: 3px;
            color: $text-white;
            padding: 10px 15px;
            transition: all 0.2s ease-in-out;
            &:hover {
                color: #0e1216;
            }
        }
        &:hover {
            background-color: white;
            color: #0e1216;
        }
    }
}