.blog-container {
    padding: 0px 96px;
    @include laptopView {
        padding: 0px 66px;
    }
}
.blogs {
    overflow-x: hidden;
    margin-top: 90px;
    margin-bottom: 40px;

    &-tabs {
        @include flexBox(center,center);
        gap: calc(10px + 1vw);
        & p {
            color: $text-primary;
            border: 1px solid #edeef1;
            border-radius: 25px;
            padding: calc(10px + 0.2vw) calc(10px + 0.4vw);
            transition: all 0.2s ease-in-out;
            &:hover {
                background-color: $bg-secondary;
                cursor: pointer;
                color: $text-white;
                transition: all 0.2s ease-in-out;
            }
        }
    }
    &-cards {
        margin-top: 40px;
        &_items {
            position: relative;

            border-radius: 12px;
            & p:first-of-type {
                height: calc(120px + 10vh);
                width: 100%;
                @include flexBox(center,center);
                border-radius: 12px 12px 0 0;
                & img {
                    border-radius: 12px 12px 0 0;
                    height: 100%;
                    width: 100%;
                }
            }
            & p:nth-of-type(2) {
                padding: calc(15px + 0.4vw);
    
            }
            & p:nth-of-type(3) {
                padding: 0 calc(15px + 0.4vw);
                font-size: $font-title;
                color: $text-primary;
            }
            & p:nth-of-type(4) {
                margin: calc(10px + 0.4vh) 0;
                padding: 0 calc(15px + 0.4vw);
            }
            & p:nth-of-type(5) {
                padding: 0 calc(15px + 0.4vw);
                @include flexBox(flex-start,center);
                gap: 7px;
                margin-bottom: 20px;
                cursor: pointer;
                .learn-more-arrow {
                    
                    transition: transform 0.3s ease-in-out;
                }
                &:hover .learn-more-arrow {
            
                    transform: translateX(10px);
                    transition: transform 0.3s ease-in-out;
                }
                &:hover {
                color: $text-secondary;
                }
            }
            transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
            border: 1px solid #ebedf000;
            &::after{
                position: absolute;
                content: "";
                height: 9px;
                width: 100%;
                top: 98%;
                border-radius: 0 0 12px 12px;
                background-color: #0172e3;
                opacity: 0;
            }
            &:hover::after {
                opacity: 1;
                    transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
                    
                }
                
                &:hover {
                    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
                    transform: scale(1);
                }
        }
    }
    &-pagination {
        margin: 50px 0;
    }
}