
.container {
    position: relative;
    & > img {
        position: absolute;
        z-index: 1;
        bottom: 80px;
    }

    .hero {
        position: absolute;
        z-index: 10;

    }
    &::after {
        position: absolute;
        content: "";
        z-index: 1;
        top: 0;

        bottom: 0;
        left: 0;
        right: 0;

        background-color: #b0d3f644;
    }
}
.hero-container {
    width: 60%;
    margin-top: 60px;
    & > p:first-of-type {
        font-size: 18px;
        color: $text-secondary;
    }
    & > p:nth-of-type(2) {
        margin-top: 5px;
        line-height: 50px;
        font-size: calc(40px + 1.1vw);
        color: $text-black;
        & span {
            color: red;
        }
    }
    & > p:nth-of-type(3) {
        font-size: calc(40px + 1.1vw);
        margin-top: -0px;
        color: $text-black;
    }
    & > p:nth-of-type(4) {
        margin-top: 15px;
        line-height: 30px;
        font-size: $font-title;
        color: $text-primary;

    }
    & > p:nth-of-type(5) {
        margin-top: 15px;
        line-height: 30px;
        font-size: $font-title;
        color: $text-primary;

    }

}
.hero-img {
    width: 40%;
    top: 10px;
    position: relative;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-70%);
        z-index: 3;
        width: 100%;
        height: calc(370px + 15vh);
        object-fit: contain;
    }
    &-container {
    & >p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-56%);
    height: calc(300px + 15vh);
    width: calc(300px + 15vh);
    background-color: #b0d3f6;
    z-index: 1;
    border-radius: 50%;
    @include flexBox(center,center);
    animation: changeBgColor 2s linear infinite;
    transition-duration: 1s;

    & > span {
        position: absolute;
        background-color: red;
        height: calc(280px + 15vh);
        width: calc(280px + 15vh);
        border-radius: 50%;
    }

}
    }
}
.hero-universites {
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    top: 95%;
    z-index: 4;
    left: 50%;
    transform: translate(-50%,-10%);
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px #0072E314;
    border-radius: 12px;
    gap: 5px;
    & > p {
        background-color: #e6f1fc;
        padding: 4px;
        white-space: no-wrap;
        border-radius: 50%;
    }
    & div {
        & p:first-of-type {
            font-size: 24px;
        }
    }

}
.hero-canada-img {
    position: absolute;
    top: 68%;
    left: 93%;
    z-index: 4;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 25px 40px 0px #0000001F;
    height: 60px;
    width: 60px;
    animation: spin 2s linear forwards;
    animation-delay: 5s;
    & img {
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}
.hero-australia-img {
    position: absolute;
    top: 15%;
    left: 88%;
    z-index: 4;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 25px 40px 0px #0000001F;
    height: 60px;
    width: 60px;
    animation: spin 2s linear forwards;
    animation-delay: 7s;
    & img {
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

}
.hero-us-img {
    position: absolute;
    top: -20%;
    left: 1%;
    z-index: 4;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 25px 40px 0px #0000001F;
    height: 60px;
    width: 60px;
    animation: spin 2s linear forwards;
    animation-delay: 1s;
    & img {
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

}
.hero-uk-img {
    position: absolute;
    top: 40%;
    left: -14%;
    z-index: 4;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 25px 40px 0px #0000001F;
    height: 60px;
    width: 60px;
    position: relative;
    animation: spin 2s linear forwards;
    animation-delay: 3s;
    & img {
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes changeBgColor {
    0% {
        background-color: #b0d3f6;
        
    }
    50% {
        background-color: #87a7cc;
    }
    100% {
        background-color: #b0d3f6;
        
    }
}


/* Delay before starting each subsequent animation */
.hero-uk-img:nth-child(odd) {
    animation-delay: 5s; /* Delay after every even iteration */
}
.hero-search {
    margin-top: 4vh;
    background-color: #ffffff;
    padding: 10px;
    @include flexBox(flex-start,center);
    box-shadow: 0px 6px 12px 0px #0000000A;

    border-radius: 12px;
    gap: 10px;
    &-courses{
        width: 46%;
        background-color: #f5f6f7;
        padding: 15px;
        border-radius: 8px;
    }
    &-location{
        width: 40%;
        background-color: #f5f6f7;
        padding: 15px;
        border-radius: 8px;
    }

    &-btn {
        width: 16%;
        & button {
            background-color: $button-primary;
            color: $button-primary-text;
            width: 100%;
            height: 52px;
            padding: 0px 24px;
            @include flexBox(center,center);
            border-radius: 8px;
        }
    }
}
.hero-trusted-clients {
    position: relative;
    margin-top: 4vh;
    display: flex;
    align-items: center;
    & >p {
        position: absolute;
        top: 0;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        @include flexBox(center,center);
        overflow: hidden;
        border: 3px solid $text-white;

        & img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    } 
    & >p:nth-of-type(1) {

        background-color: rgb(138, 138, 105);
        z-index: 10;
    }
    & p:nth-of-type(2) {
        left: 26px;
        background-color: rgb(138, 138, 105);
        z-index: 11;
    }
    & p:nth-of-type(3) {
        left: 49px;
        background-color: rgb(138, 138, 105);
        z-index: 12;
    }
    & p:nth-of-type(4) {
        left: 69px;
        background-color: rgb(138, 138, 105);
        z-index: 13;
    }
    & p:nth-of-type(5) {
        left: 89px;
        background-color: $bg-secondary;
        z-index: 13;
    }
    & div {
        margin-left: 150px;
        margin-top: 5px;
        & p {
            color: #415370;
        }
        
    }
}
.hero-bottom {
    padding: 0px 96px;
    position: absolute;
    z-index: 20;
    top: 730px;
    @include desktopView {
        top: 1000px;
    }
    @include laptopView {
        padding: 0px 66px;
        top: 730px;
    }

    gap: 4vw;
    & > div {
        background-color: #ffffff;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 25px 40px 0px #0000001F;
    }
}
.fingertips {
    position: relative;
    & span {
        position: absolute;
        z-index: 10;
        top: 98%;
        left: -10px;
        width: 115%;
        img {
            width: 100%;
        }
    }
}
.client-number {
    background-color: $bg-primary;
    color: $text-white;
}