.scholarship {
    padding: 0px 96px;
    @include laptopView {
        padding: 0px 66px;
    }
    &-allprograms {
        margin: calc(50px + 5vh) 0;
        
    }

}
.filtersearch {
    @include flexBox(center,center,column);
    background-color: $bg-secondary;
    padding: calc(25px + 0.4vw);
    border-radius: 12px;
    padding-bottom: 60px;
    position: relative;
    & p {
        color: $text-white;
    }
    & p:first-of-type {
        font-size:  $font-medium;
        color: $text-white;
    }
    & p:nth-of-type(2) {
        margin-top: 1vh;
        padding: 0 calc(150px + 10vw);
        text-align: center;
        color: $text-white;
    }
    &-input {
        position: absolute;
        top: calc(100% - 30px);
        @include flexBox(flex-start,center);
        background-color: $bg-primary;
        padding: 8px;
        gap: 6px;
        box-shadow: 0px 8px 16px 0px #0072E314;
        border-radius: 8px;
        width: calc(600px + 15vw);

        &_buttons {
            @include flexBox(flex-start,center);
            position: relative;
            gap: 10px;
            width: 14%;
            span {
                font-size: 18px;
            }
        }

        &_field {
            width: 74%;
            & input {
                width: 100%;
                padding: 0px + 15px;
            }
        }
        &_submit {
            @extend .hero-search-btn;
            width: 12%;
        }
    }
    
}
.program-card-container {
    
    margin-top: 30px;
    &-item {
        border: 1px solid #EBEDF0;
        border-radius: 12px;

        & > p {
            font-size: $font-regular;
            color: $text-primary;
            padding: 5px calc(12px + 1vw);
        }
        &-img {
            height: calc(120px + 10vh);
            @include flexBox(center,center);
            position: relative;
            background-color: #0a4081;
            width: 100%;
            border-radius: 12px 12px 0 0;
            & p {
                position: absolute;
                top: 5%;
                left: 90%;
                height: 35px;
                @include flexBox(center,center);
                border-radius: 50%;
                width: 35px;
                background-color: white;
            }
            

        }
        &_details {
            padding: calc(12px + 1vw);
            display: flex;
            flex-direction: column;
            & div {
                display: flex;
                justify-content: space-between;
                align-items: center;

            }
        }
        transition: all 0.3s ease-in-out,opacity 0.3s ease-in-out;
        position: relative;
        &::after{
            position: absolute;
            content: "";
            height: calc(5px + 0.2vw);
            width: 100%;
            top: 98%;
            border-radius: 0 0 12px 12px;
            background-color: #0172e3;
            opacity: 0;
        }
        &:hover::after {
            opacity: 1;
                transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
                
            }
            &:hover {
                box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1215686275);
                transform: scale(1.005);
            }
    }


}
.scholarship-pagination {
    margin: -40px 0 40px 0;
    padding: 0px 96px;
    @include laptopView {
        padding: 0px 66px;
    }
}