.headingtop {
    height: 80px;
    padding: 0px 96px;
    @include laptopView {
        padding: 0px 66px;
    }
    @include flexBox(space-between,center);
    border-bottom: 1px solid #EBEDF0;
    &-logo {
        @include flexBox(center,center);
        img{
            height: 40px;
            min-width: auto;
            margin-right: 30px
            
        }
        
    }
    &-navigation {
        @include flexBox(center,center);
        display: flex;
    }
    &-courses {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 21;
        height: calc(100vh - 80px);
        width: 100vw;
        transform: scaleY(0);
        opacity: 0;
        margin-top: 80px;
        transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out ;
        transform-origin: top;
    }
    &-courses-active {
        position: fixed;
        top: 0px;
        left: 0;
        margin-top: 80px;
        z-index: 21;
        height: calc(100vh - 80px);
        width: 100vw;
        transform: scaleY(1);  
        background-color: #282d3586;
        opacity: 1;

        transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out ;
        transform-origin: top;
    }

}

.headingtop-navigation-menu {
    display: flex;
    gap: calc(10px + 1vw);
}
.menu-item {
    height: 80px;
    padding: 0px 15px;
    cursor: pointer;
    @include flexBox(center,center);
    color: $text-primary;
}
.menu-item.active {
    border-bottom: 2px solid $button-primary;
}
.menu-item:hover {
    background-color: #EBEDF0;
    @include flexBox(center,center);
}

.our-sources {
    height: 70%;
    background-color: #f0f9ff;
    width: 100%;
    @include flexBox(flex-start,center);
    &-courses {
        width: 70%;
        height: 100%;
        background-color: #f2f8fe;
        padding: 0px 96px;
        @include laptopView {
            padding: 0px 66px;
        }
    
    }
    &-persuits {
        width: 30%;
        height: 100%;
        position: relative;
        
        & img {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
}

.headtop-card {
    @include flexBox(flex-start,center);
    gap: 2vw;

}
.browse-by-country {
    @extend .headingtop-courses;

}
.browse-by-country.active {
    @extend .headingtop-courses;
    @extend .headingtop-courses-active;
    overflow-y: auto;
    

}
.browse-by-country-container {
    @extend .our-sources;
    display: flex;
    align-items: flex-start;
    height: 100%;
}
.browse_by_county {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    padding: 0px 96px;
    
    @include laptopView {
        padding: 0px 66px;
    }
    &-tabs {
        margin: calc(10px + 2vw) 0;
        background-color: #f2f8fe;
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 80vh;
        
        & > div {
    
            padding: calc(8px + 0.5vh);
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            font-size: calc(12px + 0.4vw);

            & > span {
                @include flexBox(flex-start,center);
                gap: 6px;
            }
            & div {
                p {
                    color: $text-primary;
                }
            }

        }
        & >div.active {
            box-shadow: 0px 8px 16px 0px #0072E314;
            background-color: white;
            border-radius: 12px;
            & p {
                color: $text-secondary;
            }
        }
        & >div:hover {
            box-shadow: 0px 8px 16px 0px #0072E314;


            padding: calc(8px + 0.5vh);
            background-color: white;
            border-radius: 12px;
            transition: all 0.3s ease-in-out;
        }
    }
    &-content {
        margin: calc(10px + 2vw) 0;
        box-shadow: 0px 8px 16px 0px #0072E314;
        background-color: #ffffff;
        width: 80%;
        border-radius: 12px;

    }
}
.breadboard {
    display: flex;
    align-items: center;
    gap: calc(5px + 0.4vw);
    margin: 20px 0;
}

.close-showBrowse-by-country {
    background-color: rgb(181, 129, 129);
    width: 30px;

    border-radius: 6px;
    display: none;

        transition: all 0.2s ease-in-out;
    transform: scaleX(0);
}
.close-showBrowse-by-country.active {
    transition: all 0.2s ease-in-out;
    @include flexBox(center,center);
    transform: scaleX(1);
}

.browse_by_county-content_item {
    @include flexBox(space-between,flex-start);
    &-details {
        width: 75%;
        // background-color: brown;
        padding: calc(15px + 0.5vh);
        display: grid;
        grid-template-columns: 1fr 1fr;
        & >div {
            display: flex;
            flex-direction: column;
            gap: calc(15px + 1vh);
            h1 {
                margin-bottom: 10px;
                font-weight: bold;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    color: $text-secondary;
                    
                }
            }
            p {
                line-height: 30px;
            }
        }
    }
    &-img {
        width: 25%;
    }
}
.outward-line {
    position: relative;
}
.outward-line::after {
    position: absolute;
    content: "";
    top: 106%;
    left: 0;
    z-index: 10;
    height: 1.25px;
    width: 20px;
    background-color: #2b2b2b;
}