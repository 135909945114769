.login-container {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    position: relative;

    .login {
        border-radius: 12px;
        background-color: #FFF;
        overflow: hidden;
        max-width: 64rem;
        height: fit-content;
        position: absolute;
        margin: auto;
        inset: 0; /* sets left, right, top & bottom to 0 */
    }
}

.side-banner {
    background: url(/src/Assets/Images/side-banner.png) left top no-repeat;
    background-size: contain;
}

.dash {
    height: 1px;
    width: 100%;
    background-color: #EBEDF0;
}