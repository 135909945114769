.wrapper {
    padding: 0px 96px;
    margin-top: calc(100px + 4vh);

    @include laptopView {
        padding: 0px 66px;
    }

    @include flexBox(space-between, center, column);

    @include tabView {
        padding: 0px 1rem;
    }
}

.connecting-universities {
    p {
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        margin-top: 24px;
        text-align: center;
        color: $text-grey
    }

    ul.tabs {
        background-color: var(--clr-grey-1);
    }

    ul.tabs li {
        width: 274.67px;
        height: 73px;
        padding: 24px;
        border-radius: 100px;
        gap: 12px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        &.active {
            color: $text-secondary;
            background-color: white;
            box-shadow: 0px 8px 16px 0px #00000014;
            transition-duration: 0.3s;
        }
    }
}

.connecting-container {
    padding: 32px 0px;

    @include tabView {
        padding: 1rem 0rem;
    }
}

.heading1 {
    font-size: 2.4rem;
    font-family: Inter;
    line-height: 3.2rem;
    letter-spacing: 0em;
    text-align: left;

    @include tabView {
        font-size: 2rem;
        line-height: 2.2rem;
    }
}

ul.tabs {
    width: 824px;
    height: 73px;
    border-radius: 100px;
    background-color: var(--clr-grey-1);
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;

    @include tabView {
        flex-direction: column;
        width: 100%;
        height: auto;
    }
}

.connecting-card {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .img-card img {
        border-radius: 12px;
        width: 100%;
    }

    .text-card p {
        text-align: left;
    }

    @include tabView {
        grid-template-columns: repeat(1, minmax(0, 1fr));

        padding-top: 1.5rem;

        .img-card,
        .text-card {
            padding: 0px !important;
        }

        .text-card img {
            border-radius: 12px;
            width: 3rem;
        }
    }
}

.students-world-wide {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @include tabView {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.text-secondary {
    color: $text-secondary !important;
}

.text-primary {
    color: $text-primary !important;
}

@include tabView {
    .primary-button.tw-p-4 {
        padding: 0.6rem !important;
    }
}